<template>
  <div>
    <!-- FILTER -->
    <div class="flex filter-container my-5">
      <div class="w-8/12 lg:w-3/12 md:w-4/12 sm:w-8/12">
        <div class="relative">
          <div
            class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none pl-2"
          >
            <img
              class="w-4 h-4 text-gray-500 dark:text-gray-400"
              src="@/assets/svg/search.svg"
            />
          </div>
          <input
            id="default-search"
            v-model="prefix"
            type="search"
            class="
            block w-full p-4 ps-10 bg-gray-50 rounded-lg 
            text-sm text-gray-900 
            border border-gray-300 
            focus:ring-blue-500 focus:border-blue-500
            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
            dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            :placeholder="$t('dashboard.searchWebsite')"
            required
          />
        </div>
      </div>
    </div>
    <div
      class="
        main-container 
        grid grid-cols-5 grid-rows-[auto 1fr] gap-7 p-4
      "
    >
      <!-- SITES COUNT -->
      <div
        class="
          sites-count-container
          col-span-5 row-span-1 row-start-1
          lg:col-span-1 lg:row-span-1 lg:row-start-1
          md:col-span-2 md:row-span-1 md:row-start-1
          sm:col-span-5 sm:row-span-1 sm:row-start-1
          w-full lg:w-11/12 md:w-10/12 sm:w-full
        "
      >
        <div class="container-fixed-left ">
          <div class="sites-count-card ">
            <div class="sites-count-title">
              {{ $t("dashboard.Websites") }}
            </div>
            <div class="sites-count-body">
              <div class="flexChildren" @click="filterWebsitesStatus()">
                <button class="textSubtitleCounts">
                  {{ $t("dashboard.all") }}
                </button>
                <p class="counter-box counter-box-all">
                  {{ totalSites }}
                </p>
              </div>
              <div class="flexChildren" @click="filterWebsitesStatus(false)">
                <button class="textSubtitleCounts">
                  {{ $t("dashboard.free") }}
                </button>
                <p class="counter-box counter-box-free">
                  {{ totalFreeSites }}
                </p>
              </div>
              <div class="flexChildren" @click="filterWebsitesStatus(true)">
                <button class="textSubtitleCounts">
                  {{ $t("dashboard.pro") }}
                </button>
                <p class="counter-box counter-box-pro">
                  {{ totalProSites }}
                </p>
              </div>
            </div>

            <!-- <div class="flexChildren" @click="filterWebsitesStatus(true)">
              <button
                class="textSubtitleCounts"
                
              >
                {{ $t("dashboard.licences_pro_available") }}
              </button>
              <p class="counter-box counter-box-pro">
                {{ totalProLicencesAvailables }}
              </p>
            </div> -->
          </div>
        </div>
      </div>

      <!-- SITES -->
      <div
        class="sites-container
        col-span-5 row-span-1 row-start-2
        lg:col-span-4 lg:row-span-2 lg:row-start-1
        md:col-span-3 md:row-span-2 md:row-start-1
        sm:col-span-5 sm:row-span-1 sm:row-start-2"
      >
        <div
          class="
          grid gap-4 grid-cols-1
          sm:grid-cols-1
          md:grid-cols-1
          lg:grid-cols-2
          xl:grid-cols-3
        "
        >
          <dashboard-card
            class="flex items-center justify-center cursor-pointer newCard"
            @click="requestSheetsAccess"
          >
            <b-icon class="ml-2 iconSmall" name="plus" />
            <span class="is-block textNewCard">
              {{ $t("builder.new_website") }}
            </span>
            <span v-if="!hasSheetsAccess" class="text-xs">
              {{ $t("builder.google_sheets_access_needed") }}
            </span>
          </dashboard-card>
          <dashboard-card
            v-if="rootUser"
            class="flex items-center justify-center cursor-pointer"
            @click="admin"
          >
            <div>
              <b-icon name="edit" />
              <span class="is-block has-text-weight-bold"> Administrar </span>
            </div>
          </dashboard-card>
          <website-card
            v-for="website in filterWebsites"
            :key="`website-${website._id}`"
            :websites="websites"
            :website="website"
            :user="user"
            :find-my-websites="findMyWebsites"
            @upgrade="showPaymentModal"
          >
          </website-card>
        </div>
      </div>
    </div>

    <payment-modal
      :activate="isPaymentModalActive"
      :website-id="selectedWebsite.websiteId || ''"
      :website-url="selectedWebsite.domain || ''"
      @close="isPaymentModalActive = false"
    />
    <b-loading
      :is-full-page="true"
      :active.sync="isCreatingWebsite"
      :can-cancel="false"
    />
    <QrModal
      v-show="isQrModalActive"
      :website-url="selectedWebsite.domain || ''"
    />
  </div>
</template>

<script>
import ApiSites from "@/services/sites";
import BIcon from "@/components/BIcon";
import PaymentModal from "@/components/PaymentModal/PaymentModal";
import auth from "@/plugins/firebase/auth";
import db from "@/plugins/firebase/db";
import router from "@/router";
import { mapState, mapGetters } from "vuex";
import * as Sentry from "@sentry/browser";
import QRCode from "qrcode";
import DashboardCard from "./DashboardCard";
import WebsiteCard from "./WebsiteCard";
import QrModal from "../../components/QrModal.vue";
export default {
  name: "Dashboard",

  components: {
    BIcon,
    DashboardCard,
    PaymentModal,
    WebsiteCard,
    QrModal
  },

  data() {
    return {
      showMenuId: null,
      websitesMongo: [],
      websitesFirebase: [],
      dbWebsites: [],
      websites2: [],
      isCreatingWebsite: false,
      isPaymentModalActive: false,
      isQrModalActive: false,
      selectedWebsite: "",
      rootUser: false,
      prefix: "",
      filterType: "",
      numeroProDisponible: 0
    };
  },

  computed: {
    ...mapState(["hasSheetsAccess", "authorizeUrl", "user", "websites"]),
    ...mapGetters([
      "totalSites",
      "totalFreeSites",
      "totalProSites",
      "totalProLicencesAvailables",
      "proWebsites",
      "freeWebsites"
    ]),
    isPaymentsEnabled() {
      return process.env.VUE_APP_ENABLE_PAYMENTS === "true";
    },
    isAgency() {
      return this.$store.getters["isAgency"];
    },
    filterWebsites() {
      const search = this.prefix.trim().toLowerCase();
      return this.filteredWebsites.filter(
        ({ name }) => search === "" || name.toLowerCase().includes(search)
      );
    },
    filteredWebsites() {
      if (this.filterType === "pro") return this.proWebsites;
      if (this.filterType === "free") return this.freeWebsites;
      return this.websites.slice();
    }
  },
  created() {
    this.findMyWebsites();
    this.getUser();
  },
  async mounted() {
    let intervalId = setInterval(() => {
      if (this.$route.name !== "dashboard") return;
      const existsDownWebsites = this.websites.some(
        website => website?.status !== "up"
      );
      if (this.$route.name !== "dashboard") return;
      if (existsDownWebsites) {
        this.verifyStatusSite();
      } else {
        clearInterval(intervalId);
      }
    }, 30000);
  },

  methods: {
    checkConnection(url = "") {
      return new Promise(resolve => {
        const img = new Image();
        img.src = url + "/favicon.ico" + "?t=" + Date.now(); // Se agrega un parámetro único para evitar la caché
        img.onload = function() {
          resolve(true); // Conectividad exitosa
        };
        img.onerror = function() {
          resolve(false); // Error de conectividad
        };
      });
    },
    async verifyStatusSite() {
      const self = this;
      const filterdWebsites = this.websites.filter(
        website => website?.status !== "up"
      );
      for (let website of filterdWebsites) {
        const siteUrl = `https://${website.domain}`;
        if (!website.available) {
          const isAvailable = await this.checkConnection(siteUrl);
          if (isAvailable) {
            ApiSites.uptadeStatusWebsite(website.websiteId).then(({ data }) => {
              self.$store.commit("SET_REPLACE_WEBSITE", data);
            });
          }
        }
      }
    },
    async findMyWebsites() {
      this.numeroProDisponible = 0;
      let res = null;
      res = await ApiSites.findMyWebsites({
        uid: this.userId
      });
      this.$store.dispatch("setWebsites", res.data || []);
    },
    filterWebsitesStatus(WebPros) {
      if (WebPros === undefined) {
        this.filterType = "";
        return;
      }
      this.filterType = WebPros ? "pro" : "free";
    },
    async getUser() {
      try {
        this.$store.dispatch("getUser", {
          uid: this.user.uid,
          email: this.user.email
        });
        db.collection("users")
          .doc(auth.currentUser.uid)
          .onSnapshot(doc => {
            const user = doc.data();
            this.rootUser = user.rootUser;
            if (!this.hasSheetsAccess && user?.googleApisTokens) {
              this.$buefy.notification.open({
                message: "Google sheets access granted",
                type: "is-success"
              });
              this.$store.commit("SET_SHEET_ACCESS", true);
            }
          });
      } catch (error) {
        console.log(error);
      }
    },

    async requestSheetsAccess() {
      try {
        if (!this.hasSheetsAccess) {
          window.open(this.authorizeUrl);
        } else {
          const websitesLimit = this.user.websitesLimit;
          if (!this.isAgency && this.freeWebsites.length >= websitesLimit) {
            this.$buefy.notification.open({
              message: this.$t("error_messages.reached_free_ws_limit"),
              type: "is-warning"
            });
          } else {
            this.isCreatingWebsite = true;
            this.$store
              .dispatch("builder/createWebsite", {
                options: { demo: this.isAgency }
              })
              .finally(() => {
                this.isCreatingWebsite = true;
              });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },

    async generateQR() {
      try {
        const qrCode = await QRCode.toDataURL(this.websiteUrl, {
          margin: 2,
          width: 150
        });
        this.qrCode = qrCode;
      } catch (error) {
        Sentry.captureException(error);
      }
    },

    showPaymentModal(website) {
      this.isPaymentModalActive = true;
      this.selectedWebsite = website;
    },

    showQrModal(website) {
      this.isQrModalActive = true;
      this.selectedWebsite = website;
    },

    toggleOptionsMenu(websiteId) {
      if (this.showMenuId === websiteId) {
        this.showMenuId = null;
      } else {
        this.showMenuId = websiteId;
      }
    },
    admin() {
      this.$store.commit("SET_IS_ADMIN", true);
      router.push({ name: "builder" });
    }
  }
};
</script>

<style lang="scss" scoped>
.main-container {
  height: 100%;
}

.filter-container {
  justify-content: center;
}

.menuContainerWebsites {
  border-color: #d0d0d0;
  border-width: 1.5px;
  height: 30px;
  border-bottom-width: 0px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: #fefefe;
  width: 200px;
}

.container-fixed-left {
  width: 100;
}

.sites-count-card {
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.25px;
}

.sites-count-title {
  padding: 10px;
  border-radius: 10px 10px 0 0;
  background-color: white;
  border-top: 2px solid #d0d0d0;
  border-right: 2px solid #d0d0d0;
  border-bottom: 0px;
  border-left: 2px solid #d0d0d0;
  width: 100%;
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.25px;
}

.sites-count-body {
  padding: 10px;
  border-radius: 0 0 10px 10px;
  background-color: white;
  border: solid 2px #d0d0d0;
  width: 100%;
}

.textSubtitleCounts {
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.25px;
}

.flexChildren {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin: 10px 5px 10px 5px;
  padding: 20px 10px 20px 10px;
  transition: background-color 0.3s ease;
}

.flexChildren:hover {
  background-color: #eef4ff;
  border-radius: 5px;
  .textSubtitleCounts {
    font-family: Roboto;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.25px;
  }
}

.counter-box {
  width: 25px;
  height: 25px;
  border-radius: 5px;
  align-items: center;
  align-content: center;
  text-align: center;
}

.counter-box-all {
  background-color: #c9deff;
}

.counter-box-free {
  background-color: #bdc0c3;
}

.counter-box-pro {
  color: white;
  background-color: #08587a;
}

.search-bar1 {
  width: 100%;
  border-radius: 5px;
  border-color: #d0d0d0;
  border-width: 0.5px;
}

.icon-search1 {
  position: relative;
  left: -30.5%;
  height: auto;
}

.textNewCard {
  font-family: Roboto;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.25px;
}

.iconSmall {
  width: 21px;
  height: 21px;
}

.newCard {
  border: dashed 2px #93bdff;
  background-color: #fff;
  transition: background-color 0.3s ease;
  flex-direction: column;
  gap: 10px;

  .textNewCard {
    transition: font-size 0.3s ease, font-weight 0.3s ease;
  }

  /* Cambia a fila en pantallas pequeñas */
  @media (max-width: 767px) {
    flex-direction: row; /* Cambia a fila cuando es chico */
    height: 100px;
  }
}

.newCard:hover {
  background-color: #f0f6ff;

  .textNewCard {
    font-family: Roboto;
    font-weight: 500;
    font-size: 22.19px;
    letter-spacing: 0.28px;

    .iconSmall {
      width: 24px;
      height: 24px;
    }
  }
}

input {
  padding-left: 30px;
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: padding-left 0.2s ease;
}

input:focus {
  padding-left: 25px;
}
</style>
