<template>
  <div class="main-container">
    <table>
      <thead>
        <tr>
          <th
            v-for="(column, index) in columns"
            :key="index"
            class="tableHeaders"
          >
            {{ column.label }}
            <button v-if="column.sortable" @click="sortBy(column.field)">
              {{ isAscending ? "▲" : "▼" }}
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, rowIndex) in sortedData"
          :key="rowIndex"
          @click="handleRowClick($event, item)"
          @mouseenter="showTooltip($event, tooltipText)"
          @mouseleave="hideTooltip"
        >
          <td
            v-for="(column, colIndex) in columns"
            :key="colIndex"
            class="tableRows"
            @click="
              column.onClick ? column.onClick(item[column.field], item) : null
            "
          >
            <component
              :is="column.component || 'span'"
              v-if="column.component"
              v-model="item[column.field]"
              v-bind="column.props(item)"
              @click.stop
            >
              {{ item[column.field] }}
            </component>

            <a
              v-else-if="column.onClick"
              href="#"
              class="link-style"
              @click="column.onClick(item[column.field], item)"
            >
              {{
                column.addSymbol
                  ? column.addSymbol + item[column.field]
                  : item[column.field]
              }}
            </a>

            <span v-else-if="column.formatter">
              {{ column.formatter(item[column.field]) }}
            </span>
            <span v-else>
              {{
                column.addSymbol
                  ? column.addSymbol + item[column.field]
                  : item[column.field]
              }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="!data.data || data.data.length === 0" class="table-without-data">
      <div class="table-without-data-text">
        {{ noDataMessage || $t("table_without_data") }}
      </div>
    </div>
    <div v-if="data.data" class="pagination-container">
      <Paginator
        :total-pages="Math.ceil(parseInt(data.totalPages))"
        :current-page="parseInt(currentPage)"
        :total-elements="parseInt(data.totalRecords)"
        :on-page-change="onPageChange"
      />
    </div>
    <div
      v-if="tooltipText && tooltipVisible"
      :style="{ top: tooltipPosition.top, left: tooltipPosition.left }"
      class="tooltip"
    >
      <span class="tooltip-text">{{ tooltipText }}</span>
      <span class="tooltip-arrow"></span>
    </div>
  </div>
</template>

<script>
import Paginator from "./Paginator.vue";
import BSelectOrderStatus from "./BSelectOrderStatus.vue";

export default {
  components: {
    Paginator,
    BSelectOrderStatus
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    onPageChange: {
      type: Function,
      required: false,
      default: () => {}
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1
    },
    onRowClick: {
      type: Function,
      required: false,
      default: () => {}
    },
    tooltipText: {
      type: String,
      required: false,
      default: "Details"
    },
    noDataMessage: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      isAscending: true,
      sortedField: null,
      tooltipVisible: false,
      tooltipPosition: { top: 0, left: 0 }
    };
  },
  computed: {
    sortedData() {
      if (!this.sortedField) return this.data.data;

      return [...this.data.data].sort((a, b) => {
        const fieldA = a[this.sortedField];
        const fieldB = b[this.sortedField];

        if (this.isAscending) {
          return fieldA > fieldB ? 1 : fieldA < fieldB ? -1 : 0;
        } else {
          return fieldA < fieldB ? 1 : fieldA > fieldB ? -1 : 0;
        }
      });
    }
  },
  methods: {
    sortBy(field) {
      if (this.sortedField === field) {
        this.isAscending = !this.isAscending;
      } else {
        this.isAscending = true;
        this.sortedField = field;
      }
    },
    handleRowClick(event, item) {
      const ignoredTags = ["BUTTON", "A", "INPUT", "SELECT"];
      if (!ignoredTags.includes(event.target.tagName)) {
        this.onRowClick(item);
      }
    },
    showTooltip(event, text) {
      this.tooltipText = text;
      this.tooltipPosition = {
        top: event.clientY + 10 + "px",
        left: event.clientX + 10 + "px"
      };
      this.tooltipVisible = true;
    },
    hideTooltip() {
      this.tooltipVisible = false;
    }
  }
};
</script>

<style scoped>
table {
  width: 100%;
  height: 80%;
  border-collapse: collapse;
  margin-bottom: 50px;
}

.main-container {
  height: 100%;
}

.tableHeaders {
  background-color: #505057;
  align-content: center;
  color: white;
  height: 48px;
  border-bottom-width: 1px;
  border-top-width: 1px;
  border-color: #999999;
  text-align: center;
}

.tableRows {
  max-width: 35vh;
  padding: 5px 0px;
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  align-self: center;
  margin: auto;
  border-bottom: solid rgba(0, 0, 0, 0.05);
}

.link-style {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.link-style:hover {
  color: #0056b3;
}

.table-without-data {
  text-align: center;
  align-items: center;
  align-self: center;
  width: 100%;
  height: 50vh;
  margin: auto;
  border-bottom: solid #999999;
}

.table-without-data-text {
  padding-top: 100px;
  color: #999999;
  font-style: italic;
  font-size: 30px;
}

.pagination-container {
  display: flex;
  width: 100%;
  padding: 0px 20px;
  background-color: #f5f5f5;
  border-top: solid #999999;
}

.tooltip {
  position: fixed;
  padding: 5px 10px;
  background-color: #33333380;
  color: white;
  border-radius: 4px;
  font-size: 14px;
  pointer-events: none;
  z-index: 1000;
  white-space: nowrap;
  transform: translate(-50%, -50%);
}
</style>
