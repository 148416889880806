<template>
  <div class="container">
    <div class="elements">
      <p>{{ totalElements }} {{ $t("table_paginator.elements") }}</p>
    </div>
    <div class="paginator">
      <p>{{ $t("table_paginator.page") }}</p>

      <button
        :disabled="currentPage === 1"
        class="nav-button"
        @click="prevPage"
      >
        <font-awesome-icon :icon="['fas', 'chevron-left']" />
      </button>

      <button
        v-for="page in pagesToDisplay"
        :key="page"
        :class="{ active: currentPage === page }"
        class="page-button"
        @click="changePage(page)"
      >
        {{ page }}
      </button>

      <button
        :disabled="currentPage === totalPages"
        class="nav-button"
        @click="nextPage"
      >
        <font-awesome-icon :icon="['fas', 'chevron-right']" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totalPages: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    totalElements: {
      type: Number,
      required: true
    },
    onPageChange: {
      type: Function,
      required: true
    }
  },
  computed: {
    pagesToDisplay() {
      const pages = [];
      const rangeSize = 5;
      if (this.totalPages <= rangeSize) {
        for (let i = 1; i <= this.totalPages; i++) {
          pages.push(i);
        }
      } else {
        if (this.currentPage <= 3) {
          for (let i = 1; i <= rangeSize; i++) {
            pages.push(i);
          }
          pages.push("...");
          pages.push(this.totalPages);
        } else if (this.currentPage >= this.totalPages - 2) {
          pages.push(1);
          pages.push("...");
          for (
            let i = this.totalPages - (rangeSize - 1);
            i <= this.totalPages;
            i++
          ) {
            pages.push(i);
          }
        } else {
          pages.push(1);
          pages.push("...");
          pages.push(this.currentPage - 1);
          pages.push(this.currentPage);
          pages.push(this.currentPage + 1);
          pages.push("...");
          pages.push(this.totalPages);
        }
      }
      return pages;
    }
  },
  methods: {
    changePage(page) {
      if (page !== "...") {
        this.onPageChange(page);
      }
    },
    prevPage() {
      if (this.currentPage > 1) this.changePage(this.currentPage - 1);
    },
    nextPage() {
      if (this.currentPage < this.totalPages)
        this.changePage(this.currentPage + 1);
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: space-between; /* Distribuye elementos a los extremos */
  margin-top: 20px;
}

.elements {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Alinea a la izquierda */
}

.paginator {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Alinea a la derecha */
}

.page-button,
.nav-button {
  margin: 0 1px;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.page-button.active {
  background-color: #555;
  color: white;
  font-weight: bold;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #eee;
}
</style>
