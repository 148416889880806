<template>
  <div
    id="divMenuSide"
    class="rounded-md shadow border border-gray-300 bg-white text-gray-600"
  >
    <ul id="menuLong" class="menuLong p-1">
      <li
        v-if="site.isPro && site.subscription"
        class="hover:bg-gray-100 rounded-md"
      >
        <button
          class="btnOptions cursor-pointer"
          :disabled="disableCancelation"
          @click="showCancelSubscription = true"
        >
          <span :class="disableCancelation ? 'has-text-grey-light' : ''">
            {{ $t("site_options_menu.cancelSub") }}
          </span>
        </button>
      </li>
      <li v-if="site.isPro" class="hover:bg-gray-100 rounded-md">
        <img class="imgSmall" src="@/assets/icons/mdi_point-of-sale.svg" />
        <button
          class="btnOptions cursor-pointer px-4 py-2 font-medium text-left hover:text-gray-800 hover:bg-gray-100"
          @click="salesSite(site.websiteId, site.name)"
        >
          {{ $t("site_options_menu.sales") }}
        </button>
      </li>
      <li class="hover:bg-gray-100 rounded-md">
        <img class="imgSmall" src="@/assets/icons/carbon_qr-code.svg" />
        <button
          class="btnOptions cursor-pointer px-4 py-2 font-medium text-left hover:text-gray-800 hover:bg-gray-100"
          @click="showDeleteQrModal = true"
        >
          <!-- @click="showQrModal = true" -->
          {{ $t("site_options_menu.qrOption") }}
        </button>
      </li>
      <li class="hover:bg-gray-100 rounded-md">
        <span v-if="site.published">
          <img class="imgSmall" src="@/assets/icons/unpublish.svg" />
        </span>
        <span v-else>
          <img class="imgSmall" src="@/assets/icons/publish.svg" />
        </span>
        <button
          class="btnOptions cursor-pointer px-4 py-2 font-medium text-left hover:text-gray-800 hover:bg-gray-100"
          @click="publishSiteUpdate()"
        >
          <span v-if="site.published">
            {{ $t("site_options_menu.hide") }}
          </span>
          <span v-else>
            {{ $t("site_options_menu.published") }}
          </span>
        </button>
      </li>
      <li class="hover:bg-gray-100 rounded-md">
        <img class="imgSmall" src="@/assets/icons/bx_duplicate.svg" />
        <button
          class="btnOptions cursor-pointer px-4 py-2 font-medium text-left hover:text-gray-800 hover:bg-gray-100"
          @click="duplicateSite()"
        >
          {{ $t("site_options_menu.duplicated") }}
        </button>
      </li>
      <li
        fxLayout="row"
        fxLayoutAlign="start center"
        class="hover:bg-gray-100 rounded-md"
      >
        <img class="imgSmall" src="@/assets/icons/mi_delete.svg" />
        <button
          class="btnOptions cursor-pointer-red px-4 py-2 font-medium text-left hover:text-gray-800 hover:bg-gray-100"
          @click="showDeleteSiteModal = true"
        >
          {{ $t("site_options_menu.delete") }}
        </button>
      </li>
      <li v-if="isAgency && site.isPro" class="hover:bg-gray-100 rounded-md">
        <button
          class="cursor-pointer px-4 py-2 font-medium text-left w-full hover:text-gray-800 hover:bg-gray-100"
          @click="toggleDemoMode"
        >
          {{ isDemo ? "Remove demo mode" : "Set demo mode" }}
        </button>
      </li>
    </ul>
    <confirmation-modal
      :show.sync="showCancelSubscription"
      accept-text="Unsubscribe"
      @cancel="showCancelSubscription = false"
      @accept="cancelSubscription"
    >
      <p>
        {{ $t("site_options_menu.doYouCancel") }}
        <span class="font-bold"> {{ site.domain }}</span
        >?
      </p>
      <p>{{ $t("site_options_menu.doYouCancel2") }}</p>
    </confirmation-modal>
    <confirmation-modal
      :show.sync="showDeleteSiteModal"
      accept-text="Delete"
      @cancel="showDeleteSiteModal = false"
      @accept="deleteSite"
    >
      <p>
        {{ $t("site_options_menu.doYouDelete") }}
        <span class="font-bold">{{ site.domain }}</span
        >?
      </p>
      <p>{{ $t("site_options_menu.doYouDelete2") }}</p>
    </confirmation-modal>
    <QrModal
      :show.sync="showDeleteQrModal"
      accept-text="Aceptar"
      :website-url="site.domain"
      @accept="showDeleteQrModal = false"
    >
    </QrModal>
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import ApiSites from "@/services/sites";
import ApiPayments from "@/services/payments";
import ConfirmationModal from "@/components/ConfirmationModal";
import QrModal from "@/components/QrModal";
import db from "@/plugins/firebase/db";
import * as Sentry from "@sentry/browser";
import QRCode from "qrcode";
import { formatToLocalDate } from "@/utils/functions";

export default {
  name: "SiteOptionsMenu",

  components: {
    ConfirmationModal,
    QrModal
  },

  props: {
    sites: {
      type: Array,
      required: true
    },
    site: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    findMyWebsites: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isUpdatingWebsite: false,
      isDeletingWebsite: false,
      isCancelingSubscription: false,
      showCancelSubscription: false,
      showDeleteQrModal: false,
      showDeleteSiteModal: false,
      qrCode: ""
    };
  },

  computed: {
    disableCancelation() {
      return this.site.subscription.cancelAtPeriodEnd;
    },
    isAgency() {
      return this.$store.getters["isAgency"];
    },
    userId() {
      return this.$store.state.user.uid;
    },
    isLoading() {
      return (
        this.isUpdatingWebsite ||
        this.isDeletingWebsite ||
        this.isCancelingSubscription
      );
    },
    isDemo() {
      return this.site.demo || false;
    }
  },

  mounted() {
    this.generateQR();
  },
  methods: {
    async salesSite(id, name) {
      try {
        this.$router.push({
          name: "sales-site",
          params: {
            websiteId: id,
            websiteName: name,
            navTitle: this.$t("navbar_titles.orders")
          }
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    async generateQR() {
      try {
        const qrCode = await QRCode.toDataURL(this.websiteUrl, {
          margin: 2,
          width: 150
        });
        this.qrCode = qrCode;
      } catch (error) {
        Sentry.captureException(error);
      }
    },

    async duplicateSite() {
      try {
        const websitesLimit = this.user.websitesLimit;
        this.isDeletingWebsite = false;
        const freeWebsites = this.sites.filter(el => !el.isPro);
        if (!this.isAgency && freeWebsites.length >= websitesLimit) {
          this.$buefy.notification.open({
            message: this.$t("error_messages.reached_free_ws_limit"),
            type: "is-warning"
          });
        } else {
          let res = null;
          res = await ApiSites.duplicate({
            websiteId: this.site.websiteId,
            netlifyId: this.site.netlifyId,
            uid: this.userId
          });
          this.$buefy.notification.open({
            message:
              res?.data?.message ||
              this.$t("success_messages.duplicated_website"),
            type: "is-success"
          });
          this.findMyWebsites();
        }
      } finally {
        this.isDeletingWebsite = false;
        this.showDeleteSiteModal = false;
        this.showDeleteQrModal = false;
      }
    },

    async deleteSite() {
      try {
        this.isDeletingWebsite = true;
        let res = null;
        res = await ApiSites.delete({
          websiteId: this.site.websiteId,
          netlifyId: this.site.netlifyId,
          uid: this.userId
        });
        this.$buefy.notification.open({
          message:
            res?.data?.message || this.$t("success_messages.deleted_website"),
          type: "is-success"
        });
        this.findMyWebsites();
      } catch (error) {
        this.$buefy.notification.open({
          message: error?.message || this.$t("error_messages.deleted_website"),
          type: "is-danger"
        });
      } finally {
        this.isDeletingWebsite = false;
        this.showDeleteSiteModal = false;
        this.showDeleteQrModal = false;
      }
    },

    async cancelSubscription() {
      try {
        this.isCancelingSubscription = true;
        const res = (
          await ApiPayments.cancelSubscription({
            websiteId: this.site.id,
            subdomain: this.site.subdomain,
            netlifyId: this.site.netlifyId,
            subscriptionId: this.site.subscription.id,
            uid: this.userId
          })
        ).data;
        const limitDate = formatToLocalDate(
          new Date(res.subscription.periodEnd * 1000)
        );
        const formatedMessage = String(
          this.$t("subscription_modal.canceled_successfully") || ""
        ).replace(/{{date}}/, limitDate);
        this.$buefy.notification.open({
          message: formatedMessage,
          type: "is-link",
          duration: 10000
        });
        const websiteRes = await ApiSites.findMyWebsites();
        this.$store.dispatch("setWebsites", websiteRes.data || []);
      } catch (error) {
        this.$buefy.notification.open({
          message: this.$t("error_messages.cancel_subscription"),
          type: "is-danger"
        });
      } finally {
        this.isCancelingSubscription = false;
        this.showCancelSubscription = false;
        this.showQrSubscription = false;
      }
    },
    async publishSiteUpdate() {
      try {
        this.isUpdatingWebsite = true;

        const websiteRef = await ApiSites.hide({
          websiteId: this.site.websiteId,
          netlifyId: this.site.netlifyId,
          uid: this.userId,
          isPublished: this.site.published ? false : true
        });
        this.$buefy.notification.open({
          message:
            websiteRef?.data?.message ||
            this.$t("success_message.updated_website"),
          type: "is-success"
        });
        this.findMyWebsites();
      } catch (error) {
        this.$buefy.notification.open({
          message: error?.message || this.$t("error_messages.updated_website"),
          type: "is-danger"
        });
      } finally {
        this.isUpdatingWebsite = false;
        this.isDeletingWebsite = false;
        this.showDeleteSiteModal = false;
        this.showDeleteQrModal = false;
      }
    },
    async toggleDemoMode() {
      try {
        this.isUpdatingWebsite = true;
        const websiteRef = db
          .collection("users")
          .doc(this.userId)
          .collection("websites")
          .doc(this.site.id);
        const websiteRes = await websiteRef.update({
          demo: this.isDemo ? false : true
        });
        this.$buefy.notification.open({
          message:
            websiteRes?.data?.message ||
            this.$t("success_message.updated_website"),
          type: "is-success"
        });
      } catch (error) {
        this.$buefy.notification.open({
          message: error?.message || this.$t("error_messages.updated_website"),
          type: "is-danger"
        });
      } finally {
        this.isUpdatingWebsite = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.site-options-menu-wrapper {
  top: 40px;
  right: 25px;
  z-index: 1;
}
.cursor-pointer-red {
  color: red;
}
.iconRed {
  color: red;
}
.imgSmall {
  width: 20px;
  height: 20px;
  max-width: none !important;
  display: inline;
}
.menuLong {
  width: 200px;
  min-width: 150px;
}
.Qr-modal button.cancel-button {
  display: none;
}
.btnOptions {
  width: 110px;
}
</style>
