<template>
  <div class="container containerDatePicker mx-auto px-4 sm:px-0">
    <div class="flex">
      <Button class="buttonMenuPicker" @click="showMenu()">
        <div>
          <img class="imgSmall" src="@/assets/icons/date-range.svg" />
          {{ texto }}
        </div>
        <img class="imgSmall" src="@/assets/icons/chevron-right.svg" />
      </Button>
      <transition name="fade">
        <div v-if="show" class="menuDropDownButton">
          <div>
            <button
              id="btnToday"
              class="btnDates"
              @click="showDate('btnToday')"
            >
              {{ $t("date_picker.today") }}
            </button>
          </div>
          <div>
            <button id="btnWeek" class="btnDates" @click="showDate('btnWeek')">
              {{ $t("date_picker.this_week") }}
            </button>
          </div>
          <div>
            <button
              id="btnMonth"
              class="btnDates"
              @click="showDate('btnMonth')"
            >
              {{ $t("date_picker.this_month") }}
            </button>
          </div>
          <div>
            <button id="btnYear" class="btnDates" @click="showDate('btnYear')">
              {{ $t("date_picker.this_year") }}
            </button>
          </div>
          <div class="date-filter-container">
            <div class="date-labels">
              <p>{{ $t("date_picker.from") }}</p>
              <p>{{ $t("date_picker.until") }}</p>
            </div>
            <div class="date-picker-inputs">
              <date-picker
                v-model="dateStart"
                class="date-picker"
                value-type="date"
                format="DD/MM/YYYY"
                :clearable="true"
              />
              <date-picker
                v-model="dateEnd"
                class="date-picker"
                value-type="date"
                format="DD/MM/YYYY"
                :clearable="true"
              />
            </div>
            <div class="apply-button-container">
              <button
                id="btnApply"
                class="btn-apply"
                @click="applyFilterDate()"
              >
                {{ $t("date_picker.apply") }}
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear
} from "date-fns";

export default {
  name: "DatePickerComponent",

  components: {
    DatePicker
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  data() {
    return {
      texto: this.$t("date_picker.today"),
      show: false,
      dateStart: "",
      dateEnd: ""
    };
  },
  computed: {},
  methods: {
    async showMenu() {
      this.show = !this.show;
    },
    async closeOptionsMenu() {
      this.show = false;
    },
    async showDate(id) {
      const buttons = ["btnToday", "btnWeek", "btnMonth", "btnYear"];
      const activeStyle = {
        backgroundColor: "#0a69ff",
        color: "#fff"
      };
      const inactiveStyle = {
        backgroundColor: "#fff",
        color: "#262626"
      };

      const applyStyles = (buttonId, isActive) => {
        const element = document.getElementById(buttonId);
        const styles = isActive ? activeStyle : inactiveStyle;
        element.style.backgroundColor = styles.backgroundColor;
        element.style.color = styles.color;
      };

      buttons.forEach(button => applyStyles(button, button === id));

      switch (id) {
        case "btnToday":
          this.texto = this.$t("date_picker.today");
          this.dateStart = new Date();
          this.dateEnd = new Date();
          break;
        case "btnWeek":
          this.texto = this.$t("date_picker.this_week");
          this.dateStart = startOfWeek(new Date(), { weekStartsOn: 1 });
          this.dateEnd = endOfWeek(new Date(), { weekStartsOn: 1 });
          break;
        case "btnMonth":
          this.texto = this.$t("date_picker.this_month");
          this.dateStart = startOfMonth(new Date());
          this.dateEnd = endOfMonth(new Date());
          break;
        case "btnYear":
          this.texto = this.$t("date_picker.this_year");
          this.dateStart = startOfYear(new Date());
          this.dateEnd = endOfYear(new Date());
          break;
        default:
          break;
      }
    },
    applyFilterDate() {
      if (this.dateStart && this.dateEnd) {
        const formattedDateStart = this.formatDate(this.dateStart);
        const formattedDateEnd = this.formatDate(this.dateEnd);

        this.$emit("dateChange", {
          dateStart: formattedDateStart,
          dateEnd: formattedDateEnd
        });
      } else {
        this.$emit("dateChange", {
          dateStart: "",
          dateEnd: ""
        });
      }
      this.show = false;
    },
    formatDate(date) {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, "0");
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.containerDatePicker {
  background-color: white;
  border-radius: 5px;
}
.imgSmall {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  display: inline;
}

.buttonMenuPicker {
  width: 100%;
  height: 34px;
  flex-direction: row;
  background-color: transparent;
  border-radius: 5px;
  border-color: #d0d0d0;
  border-width: 0.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date-filter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.date-labels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
}

.date-picker-inputs {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.date-picker {
  width: 48%;
}

.apply-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.btn-apply {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}

.btn-apply:hover {
  background-color: #333;
}

.btnDates {
  background-color: #fff;
  width: 160px;
  color: 262626;
  border-radius: 5px;
  margin: 2%;
  border-color: #d0d0d0;
  border-width: 0.5px;
}

.btnApply {
  background-color: #121315;
  color: #fff;
  width: 142px;
  border-radius: 5px;
  margin: 2%;
  height: 40px;
}

.menuDropDownButton {
  display: flex;
  flex-direction: column;
  margin: 2%;
  width: 325px;
  top: 40px;
  z-index: 11;
  position: absolute;
  background-color: white;
  border-radius: 2%;
  box-shadow: 2px 4px 14px 0px rgba(0, 0, 0, 0.25);
}

.letterDatePicker {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top-width: 1px;
}

.datePickerDiv {
  display: flex;
  justify-content: center;
}

.datePickerStyle {
  width: 40%;
  margin: 2%;
}
</style>
