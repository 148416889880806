<!-- eslint-disable vue/attribute-hyphenation -->
<!-- eslint-disable prettier/prettier -->
<template>
  <div>
    <div class="container mx-auto py-4 px-4 sm:px-0 main" style="max-width: none;">
      <!-- <Tittle> -->
      <div class="grid grid-cols-3 mx-5">
        <div class="grid">
          <button class="buttonMainSales" @click="returnMainPage">
            <img class="imgSmall" src="@/assets/icons/home.svg" />
            <span>{{ $t("sales_website.start") }}</span>
          </button>
        </div>
        <div class="grid textAlignCenter">
          <span class="textNewCard">
            {{ websiteName }}
          </span>
        </div>
      </div>
      <!-- </Tittle> -->

      <!-- <OptionsAndFilters> -->
      <div id="containerFilters" class="grid grid-cols-12 justify-center items-end textAlignCenter">

        <!-- <Options> -->
        <div class="grid col-span-4">
          <div class="grid grid-cols-4">
            <div class="grid">
              <button
                id="BtnOrders"
                :class="{ BtnOptionSelected: selectedButton === 'BtnOrders', BtnOption: selectedButton !== 'BtnOrders' }"
                @click="changeFocus('BtnOrders')"
              >
                <img class="imgSmall" src="@/assets/icons/round-list.svg" />
                {{ $t("sales_website.orders") }}
              </button>
            </div>
            <div class="grid">
              <button
                id="BtnSales"
                :class="{ BtnOptionSelected: selectedButton === 'BtnSales', BtnOption: selectedButton !== 'BtnSales' }"
                @click="changeFocus('BtnSales')"
              >
                <img class="imgSmall" src="@/assets/icons/chart-line.svg" />
                {{ $t("sales_website.sales") }}
              </button>
            </div>
            <div class="grid">
              <button
                id="BtnCustomers"
                :class="{ BtnOptionSelected: selectedButton === 'BtnCustomers', BtnOption: selectedButton !== 'BtnCustomers' }"
                @click="changeFocus('BtnCustomers')"
              >
                <img class="imgSmall" src="@/assets/icons/carbon_customer.svg" />
                {{ $t("sales_website.customers") }}
              </button>
            </div>
          </div>
        </div>
        <!-- <Options> -->

        <!-- <Filters> -->
        <div class="grid col-span-8">
          <div class="grid grid-cols-3">
            <div v-show="selectedButton === 'BtnOrders'" id="filterSearch" class="grid items-end content-end">
              <div class="grid search-bar">
                <input v-model="filter" :placeholder="$t('sales_website.search')" icon-name="find" class="filterSearch" />
                <b-icon class="icon-search" name="search" />
              </div>
            </div>

            <div v-show="selectedButton === 'BtnCustomers'" class="grid items-end content-end">
              <div class="grid search-bar">
                <input v-model="customerFilter" :placeholder="$t('customers.filter_text')" icon-name="find" class="filterSearch" />
                <b-icon class="icon-search" name="search" />
              </div>
            </div>

            <div v-show="selectedButton === 'BtnOrders'" id="filterDatePicker" class="grid items-end content-end">
              <DatePickerComponent @dateChange="handleDateChange" />
            </div>
            <div v-show="selectedButton === 'BtnOrders'" id="filterEstatus" class="grid items-end	content-center">
              <p class="ml-5 justifySelfStart">
                {{ $t("sales_website.status") }}
              </p>
              <select v-model="status" name="status" class="selectInside" @change="filteredVentasEstatus()">
                <option value="">{{ $t("sales_website.all") }}</option>
                <option :value="ORDER_STATUS.PENDING">
                  {{ $t("sales_website.pending") }}
                </option>
                <option :value="ORDER_STATUS.IN_PREPARATION">
                  {{ $t("sales_website.in_preparation") }}
                </option>
                <option :value="ORDER_STATUS.READY">
                  {{ $t("sales_website.ready") }}
                </option>
                <option :value="ORDER_STATUS.ON_THE_WAY">
                  {{ $t("sales_website.on_the_way") }}
                </option>
                <option :value="ORDER_STATUS.DELIVERED">
                  {{ $t("sales_website.delivered") }}
                </option>
                <option :value="ORDER_STATUS.CANCELLED">
                  {{ $t("sales_website.cancelled") }}
                </option>
              </select>
            </div>
          </div>
          
        </div>
        <!-- </Filters> -->
      </div>
      <!-- </OptionsAndFilters> -->

      <!-- <ORDERS SECTION> -->
      <div class="creative-bar">
        <div v-show="selectedButton === 'BtnOrders'" id="containerTable" class="containerSection">
          <BTable
            :data="orders"
            :columns="orderColumns"
            :currentPage="currentPage"
            :onRowClick="getOrderDetails"
            :tooltipText="$t('sales_website.tooltip_text')"
            :onPageChange="onPageChange"
          />
        </div>
        <OrderDetailsModal
          :show="isModalVisible"
          :title="$t('modal_order_details.title')" 
          cancel-button-disabled
          :accept-text="$t('modal_order_details.accept_text')"
          accept-text-style-type="dark" :center-buttons="true"
          :order="order"
          @cancel="closeModal"
          @accept="closeModal" 
        />
      </div>
      <!-- </ORDERS SECTION> -->

      <!-- <SALES SECTION> -->
      <div class="creative-bar">
        <div v-show="selectedButton === 'BtnSales'" id="containerSales" class="containerSection">
          <div class="salesContainer">
            <div class="grid grid-cols-2 grid-rows-2">
              <div class="grid col-span-2 grid-cols-4 grid-rows-1 gap-5 saleDetails">
                <div class="saleDetailCard">
                  <div class="titleCard">{{ $t("sales_chart.card_titles.total") }}</div>
                  <div class="valueCard">{{ totalSalesSite }}</div>
                </div>
                <div class="saleDetailCard">
                  <div class="titleCard">{{ $t("sales_chart.card_titles.day_sales") }}</div>
                  <div class="valueCard">{{ dailySales }}</div>
                </div>
                <div class="saleDetailCard">
                  <div class="titleCard">{{ $t("sales_chart.card_titles.total_items") }}</div>
                  <div class="valueCard">{{ productsSold }}</div>
                </div>
                <div class="saleDetailCard">
                  <div class="titleCard">{{ $t("sales_chart.card_titles.total_orders") }}</div>
                  <div class="valueCard">{{ totalOrders }}</div>
                </div>
              </div>
              <div class="row-start-2 detailsChart">
                <div class="titleSales">{{ $t("sales_website.sales") }}</div>
                <div class="totalValueSales">{{ totalSales }}</div>
                <div class="subtitleSales">
                  <span 
                    class="subtitleSpanSales" 
                    :class="{'negative': salesNumbers.comparisonPercentage < 0}"
                  >
                    {{ comparisonPercentage }}
                  </span>                  
                  {{ percentTextSelected }}
                </div>
              </div>
              <div class="row-start-2 detailsChart">
                <div class="grid grid-cols-3 grid-rows-1 gap-4 optionsChart">
                  <button id="weekButton" class="chartButtonSelected" @click="onChangeDataChart('week', 'weekButton')">{{ $t("sales_chart.week") }}</button>
                  <button id="monthButton" class="chartButton" @click="onChangeDataChart('month', 'monthButton')">{{ $t("sales_chart.month") }}</button>
                  <button id="yearButton" class="chartButton" @click="onChangeDataChart('year', 'yearButton')">{{ $t("sales_chart.year") }}</button>
                </div>
              </div>
            </div>
            <div class="salesChart">
              <LineChart id="chartData" :chart-data="computedChartData" />
            </div>
          </div>
        </div>
      </div>
      <!-- </SALES SECTION> -->

      <!-- <CUSTOMERS SECTION> -->
      <div class="creative-bar">
        <div v-show="selectedButton === 'BtnCustomers'" class="containerSection">
          <div id="containerTable" class="containerSection block">
            <BTable 
              :data="customers"
              :columns="customerColumns"
              :currentPage="currentPageCustomers"
              :onPageChange="onPageCustomersChange"
              :noDataMessage="$t('customers.table_without_data')"
              :onRowClick="row => openModalCustomerOrderList(row)"
              :tooltip-text="$t('customers.tooltip_text')"
              />
          </div>
          <CustomerOrdersModal 
            :show="isCustomerOrdersModalVisible" 
            :userData="customerData"
            :websiteId="websiteId"
            @cancel="closeModalCustomerOrderList"
            @accept="closeModalCustomerOrderList"
          />
        </div>
      </div>
      <!-- </CUSTOMERS SECTION> -->
    </div>
  </div>
</template>

<script>
//#region GENERALS
import * as Sentry from "@sentry/browser";
import "vue2-datepicker/index.css";
import { mapState } from "vuex";
import { debounce } from "lodash-es";
//#endregion

//#region UTILS
import {
  dateFormart,
  orderFormat,
  dateFormartCustomers
} from "../../utils/helpers";
import { ORDER_STATUS } from "../../utils/constants";
//#endregion

//#region SERVICES
import apiOrders from "@/services/orders";
import apiUsers from "@/services/users";
//#endregion

//#region COMPONENTS
import BIcon from "@/components/BIcon";
import BTable from "../../components/BTable.vue";
import DatePickerComponent from "./datePicker.vue";
import OrderDetailsModal from "../../components/OrderDetailsModal.vue";
import CustomerOrdersModal from "../../components/CustomerOrdersModal.vue";
import LineChart from "../../components/LineChart.vue";
//#endregion

export default {
  name: "SalesWebsite",

  components: {
    BIcon,
    DatePickerComponent,
    BTable,
    OrderDetailsModal,
    CustomerOrdersModal,
    LineChart
  },
  props: {
    websiteId: {
      type: String,
      required: true
    },
    websiteName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeButton: "BtnOrders",
      ORDER_STATUS,
      filter: "",
      chartFilter: "week",
      debouncedSearch: null,
      customerDebouncedSearch: null,
      dateStart: "",
      dateEnd: "",
      status: "",
      isModalVisible: false,
      isCustomerOrdersModalVisible: false,
      orderColumns: [
        {
          label: this.$t("table_orders_headers.number"),
          field: "orderId",
          formatter: row => "#" + row
        },
        {
          label: this.$t("table_orders_headers.date"),
          field: "createdAt",
          formatter: dateFormart
        },
        {
          label: this.$t("table_orders_headers.items"),
          field: "items",
          formatter: row => {
            const { orderItems } = orderFormat(row);
            return orderItems;
          }
        },
        {
          label: this.$t("table_orders_headers.quantity"),
          field: "items",
          formatter: row => {
            const { quantity } = orderFormat(row);
            return quantity;
          }
        },
        {
          label: this.$t("table_orders_headers.estatus"),
          field: "status",
          component: "BSelectOrderStatus",
          props: row => ({
            status: row.status,
            confirmChange: true,
            deliveryType: row.deliveryType,
            onChange: newStatus => {
              this.updateStatusOrder(row.orderId, newStatus);
            }
          })
        },
        {
          label: this.$t("table_orders_headers.payment"),
          field: "paymentType",
          formatter: row => this.$t("payment_types." + row)
        },
        {
          label: this.$t("table_orders_headers.delivery"),
          field: "deliveryType",
          formatter: row => this.$t("delivery_types." + row),
          icon: "truck-fast"
        },
        {
          label: this.$t("table_orders_headers.total"),
          field: "total",
          formatter: row => "$" + row
        }
      ],
      customerColumns: [
        {
          label: this.$t("customers.table_customers_headers.name"),
          field: "name"
        },
        {
          label: this.$t("customers.table_customers_headers.email"),
          field: "email"
        },
        {
          label: this.$t("customers.table_customers_headers.last_order_date"),
          field: "lastOrderDate",
          formatter: dateFormartCustomers
        },
        {
          label: this.$t("customers.table_customers_headers.orders"),
          field: "totalOrders",
          formatter: row => this.totalCustomerOrders(row)
        },
        {
          label: this.$t("customers.table_customers_headers.register_date"),
          field: "createdAt",
          formatter: dateFormartCustomers
        }
      ],
      order: {},
      sales: {},
      salesNumbers: {},
      chartData: {
        labels: this.$t("sales_chart.chart_filter.week"),
        datasets: [
          {
            label: this.$t("sales_chart.card_titles.total"),
            color: "#8fb7fb",
            borderColor: "#8fb7fb",
            data: [],
            fill: true,
            backgroundColor: ctx => {
              if (!ctx.chart) return;
              const canvas = ctx.chart.ctx;
              const gradient = canvas.createLinearGradient(
                0,
                0,
                0,
                canvas.canvas.height
              );

              gradient.addColorStop(0, "rgba(143, 183, 251, 1)");
              gradient.addColorStop(0.5, "rgba(143, 183, 251, 0)");
              gradient.addColorStop(1, "rgba(143, 183, 251, 0)");

              return gradient;
            }
          }
        ]
      },
      customers: {},
      customerData: {},
      customerFilter: "",
      currentPageCustomers: 1,
      newStatus: "",
      selectedButton: "BtnOrders"
    };
  },
  computed: {
    ...mapState("orders", "currentPage"),
    orders: {
      get() {
        return this.$store.state.orders;
      }
    },
    currentPage: {
      get() {
        return this.$store.state.currentPage;
      }
    },
    computedChartData() {
      let labels;
      if (this.chartFilter === "month") {
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth();
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        labels = Array.from({ length: daysInMonth }, (_, i) => i + 1);
      } else {
        labels = this.$t(`sales_chart.chart_filter.${this.chartFilter}`);
      }
      return {
        ...this.chartData,
        labels
      };
    },
    totalSalesSite() {
      return `$${this.salesNumbers.totalSalesSite}` || "N/A";
    },
    totalSales() {
      return `$${this.salesNumbers.totalSales}` || "N/A";
    },
    dailySales() {
      return `$${this.salesNumbers.dailySales}` || "N/A";
    },
    totalOrders() {
      return this.salesNumbers.totalOrders || "0";
    },
    productsSold() {
      return this.salesNumbers.productsSold || "0";
    },
    comparisonPercentage() {
      const percentage = this.salesNumbers.comparisonPercentage;
      if (percentage > 0) {
        return `+${percentage}%`;
      }
      return `${percentage}%` || "0";
    },
    percentTextSelected() {
      const filterTexts = {
        week: this.$t("sales_chart.since_last_week"),
        month: this.$t("sales_chart.since_last_month"),
        year: this.$t("sales_chart.since_last_year")
      };
      return filterTexts[this.chartFilter] || "";
    }
  },
  watch: {
    filter() {
      this.debouncedSearch();
    },
    status() {
      this.getOrders();
    },
    dateStart() {
      this.getOrders();
    },
    dateEnd() {
      this.getOrders();
    },
    order() {
      this.openModal();
    },
    customerFilter() {
      this.customerDebouncedSearch();
    }
  },
  created() {
    this.getOrders();
    this.getSalesData();
    this.debouncedSearch = debounce(this.getOrders, 500);
    this.customerDebouncedSearch = debounce(this.getCustomers, 500);
    this.getCustomers();
  },
  methods: {
    async handleDateChange({ dateStart, dateEnd }) {
      this.dateStart = dateStart;
      this.dateEnd = dateEnd;
    },
    async getOrders() {
      try {
        const res = await apiOrders.getOrders(this.websiteId, {
          filter: this.filter,
          dateStart: this.dateStart,
          dateEnd: this.dateEnd,
          status: this.status,
          page: this.currentPage
        });

        const orders = res.data || [];
        this.$store.dispatch("setOrdersData", orders);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$buefy.notification.open({
            message: this.$t("api_response.code_401"),
            type: "is-warning"
          });
          this.returnMainPage();
        }
      }
    },
    async getOrderDetails(id) {
      try {
        this.order = {};
        const res = await apiOrders.getOrderDetails(id._id);
        this.order = res.data.data || {};
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$buefy.notification.open({
            message: this.$t("api_response.code_401"),
            type: "is-warning"
          });
          this.returnMainPage();
        }
      }
    },
    async updateStatusOrder(orderId, status) {
      const body = {
        status,
        websiteId: this.websiteId
      };
      try {
        await apiOrders.updateStatusOrder(orderId, body);
        if (this.isModalVisible && status === ORDER_STATUS.CANCELLED) {
          this.isModalVisible = !this.isModalVisible;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$buefy.notification.open({
            message: this.$t("api_response.code_401"),
            type: "is-warning"
          });
          this.returnMainPage();
        }
      }
      this.getOrders();
    },
    async getSalesData() {
      try {
        const res = await apiOrders.getSales(this.websiteId);
        this.sales = res.data || [];
        this.getFilteredSalesData(this.sales, this.chartFilter);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$buefy.notification.open({
            message: this.$t("api_response.code_401"),
            type: "is-warning"
          });
          this.returnMainPage();
        }
      }
    },
    async getCustomerOrders(userId) {
      try {
        const res = await apiOrders.getCustomerOrders(this.websiteId, userId);
        this.customerOrders = res.data || [];
        this.getFilteredSalesData(this.sales, this.chartFilter);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$buefy.notification.open({
            message: this.$t("api_response.code_401"),
            type: "is-warning"
          });
          this.returnMainPage();
        }
      }
    },
    onPageChange(page) {
      this.$store.dispatch("setPage", page);
      this.getOrders();
    },
    async returnMainPage() {
      try {
        this.$router.push({
          name: "dashboard"
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    async changeFocus(id) {
      this.selectedButton = id;
    },
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    formatDate(date) {
      return dateFormart(date);
    },
    onChangeDataChart(dataType, id) {
      const buttons = {
        weekButton: document.getElementById("weekButton"),
        monthButton: document.getElementById("monthButton"),
        yearButton: document.getElementById("yearButton")
      };

      Object.values(buttons).forEach(button => {
        button.style.boxShadow = "none";
        button.style.color = "#777676";
        button.style.backgroundColor = "";
      });

      Object.values(buttons).forEach(button => {
        button.style.boxShadow = "none";
        button.style.color = "#777676";
        button.style.backgroundColor = "";
      });

      buttons[id].style.boxShadow = "2px 2px 10px 0px #00000040";
      buttons[id].style.color = "#3281ff";
      buttons[id].style.backgroundColor = "white";

      this.chartFilter = dataType;
      this.getFilteredSalesData(this.sales, dataType);
    },
    filterOrdersByDate(orders, filterType) {
      const now = new Date();

      if (filterType === "week") {
        const startOfWeek = new Date(now);
        const day = startOfWeek.getDay();
        const diff = day === 0 ? 6 : day - 1;
        startOfWeek.setDate(now.getDate() - diff);
        startOfWeek.setHours(0, 0, 0, 0);

        return orders.filter(order => {
          const createdAt = new Date(order.createdAt);
          return createdAt >= startOfWeek && createdAt <= now;
        });
      }

      if (filterType === "month") {
        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        startOfMonth.setHours(0, 0, 0, 0);
        endOfMonth.setHours(23, 59, 59, 999);
        return orders.filter(order => {
          const createdAt = new Date(order.createdAt);
          createdAt.setHours(0, 0, 0, 0);
          return createdAt >= startOfMonth && createdAt <= endOfMonth;
        });
      }

      if (filterType === "year") {
        const startOfYear = new Date(now.getFullYear(), 0, 1);
        const endOfYear = new Date(now.getFullYear(), 11, 31, 23, 59, 59);
        return orders.filter(order => {
          const createdAt = new Date(order.createdAt);
          return createdAt >= startOfYear && createdAt <= endOfYear;
        });
      }

      return orders;
    },
    calculateSalesSite(orders) {
      let totalSalesSite = 0;
      orders.forEach(order => {
        totalSalesSite += order.subtotal;
      });
      return totalSalesSite;
    },
    calculateSales(orders) {
      const now = new Date();
      let totalSales = 0;
      let dailySales = 0;
      let totalOrders = orders.length;

      orders.forEach(order => {
        totalSales += order.subtotal;
        const createdAt = new Date(order.createdAt);
        if (createdAt.toDateString() === now.toDateString()) {
          dailySales += order.subtotal;
        }
      });

      return { totalSales, dailySales, totalOrders };
    },
    getProductsSold(orders) {
      let productCounts = 0;
      orders.forEach(order => {
        order.items.forEach(item => {
          productCounts += item.quantity;
        });
      });

      return productCounts;
    },
    calculateComparisonPercentage(currentTotal, previousTotal) {
      if (previousTotal === 0) return currentTotal === 0 ? 0 : 100;
      return ((currentTotal - previousTotal) / previousTotal) * 100;
    },
    getFilteredSalesData(orders, filterType) {
      const now = new Date();
      const salesData = [];
      const filteredOrders = this.filterOrdersByDate(orders, filterType);

      if (filterType === "week") {
        const weeklySales = Array(7).fill(0);

        filteredOrders.forEach(order => {
          const createdAt = new Date(order.createdAt);
          const dayOfWeek = createdAt.getDay();
          const salesAmount = order.subtotal;

          weeklySales[(dayOfWeek + 6) % 7] += salesAmount;
        });

        salesData.push(...weeklySales);
      } else if (filterType === "month") {
        const daysInMonth = new Date(
          now.getFullYear(),
          now.getMonth() + 1,
          0
        ).getDate();

        const monthlySales = Array(daysInMonth).fill(0);

        filteredOrders.forEach(order => {
          const createdAt = new Date(order.createdAt);
          const dayOfMonth = createdAt.getDate();
          const salesAmount = order.subtotal;

          monthlySales[dayOfMonth] += salesAmount;
        });

        salesData.push(...monthlySales);
      } else if (filterType === "year") {
        const yearlySales = Array(12).fill(0);

        filteredOrders.forEach(order => {
          const createdAt = new Date(order.createdAt);
          const month = createdAt.getMonth();
          const salesAmount = order.subtotal;

          yearlySales[month] += salesAmount;
        });

        salesData.push(...yearlySales);
      }

      this.chartData.datasets[0].data = salesData;

      const { totalSales, dailySales, totalOrders } = this.calculateSales(
        filteredOrders
      );
      const productsSold = this.getProductsSold(filteredOrders);

      let previousPeriodOrders;
      if (filterType === "week") {
        const startOfPreviousWeek = new Date(now);
        startOfPreviousWeek.setDate(now.getDate() - now.getDay() - 6);
        startOfPreviousWeek.setHours(0, 0, 0, 0);

        const endOfPreviousWeek = new Date(startOfPreviousWeek);
        endOfPreviousWeek.setDate(startOfPreviousWeek.getDate() + 6);
        endOfPreviousWeek.setHours(23, 59, 59, 999);

        previousPeriodOrders = orders.filter(order => {
          const createdAt = new Date(order.createdAt);
          return (
            createdAt >= startOfPreviousWeek && createdAt <= endOfPreviousWeek
          );
        });
      } else if (filterType === "month") {
        const startOfPreviousMonth = new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          1
        );
        const endOfPreviousMonth = new Date(
          now.getFullYear(),
          now.getMonth(),
          0
        );

        previousPeriodOrders = orders.filter(order => {
          const createdAt = new Date(order.createdAt);
          return (
            createdAt >= startOfPreviousMonth && createdAt <= endOfPreviousMonth
          );
        });
      } else if (filterType === "year") {
        const startOfPreviousYear = new Date(now.getFullYear() - 1, 0, 1);
        const endOfPreviousYear = new Date(
          now.getFullYear() - 1,
          11,
          31,
          23,
          59,
          59
        );

        previousPeriodOrders = orders.filter(order => {
          const createdAt = new Date(order.createdAt);
          return (
            createdAt >= startOfPreviousYear && createdAt <= endOfPreviousYear
          );
        });
      }

      const { totalSales: previousTotalSales } = this.calculateSales(
        previousPeriodOrders
      );
      const comparisonPercentage = this.calculateComparisonPercentage(
        totalSales,
        previousTotalSales
      );
      const totalSalesSite = this.calculateSalesSite(orders);
      this.salesNumbers = {
        totalSalesSite: parseFloat(totalSalesSite).toFixed(2),
        totalSales: parseFloat(totalSales).toFixed(2),
        dailySales: parseFloat(dailySales).toFixed(2),
        totalOrders: totalOrders,
        productsSold: productsSold,
        comparisonPercentage: parseFloat(comparisonPercentage).toFixed(2)
      };
    },
    async getCustomers() {
      try {
        const res = await apiUsers.getCustomers(this.websiteId, {
          filter: this.customerFilter,
          page: this.currentPageCustomers
        });

        this.customers = res.data || [];
        //this.$store.dispatch("setOrdersData", orders);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$buefy.notification.open({
            message: this.$t("api_response.code_401"),
            type: "is-warning"
          });
          this.returnMainPage();
        }
      }
    },
    onPageCustomersChange(page) {
      this.currentPageCustomers = page;
      this.getCustomers();
    },
    totalCustomerOrders(numOrders) {
      return numOrders > 0 ? numOrders : this.$t("customers.without_orders");
    },
    openModalCustomerOrderList(customer) {
      this.customerData = customer;
      this.isCustomerOrdersModalVisible = true;
    },
    closeModalCustomerOrderList() {
      this.isCustomerOrdersModalVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  height: 100%;
}

.imgSmall {
  display: inline;
  margin-right: 5px;
}

.StatusSelect {
  margin-left: 2%;
  margin-right: 1%;
  margin-bottom: 2%;
  justify-content: center;
  align-items: baseline;
  align-content: center;
  display: flex;
  flex-direction: column;
}

.selectInside {
  width: 50%;
  border-width: 1.5px;
  border-radius: 5px;
  height: 34px;
  margin-left: 5%;
  margin-right: 10%;
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.25px;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.BtnOptionSelected {
  width: auto;
  height: auto;
  padding: 10px 20px;
  color: #262626;
  justify-self: end;
  align-content: center;
  border-radius: 20px;
  box-shadow: 2px 2px 10px 0px #00000040;
  margin-left: 2%;
  margin-right: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.BtnOptionSelected:hover {
  transform: scale(1.05);
  box-shadow: 4px 4px 15px 0px #00000070;
}

.BtnOptionSelected:active {
  transform: scale(0.95);
}

.BtnOption {
  width: auto;
  height: auto;
  padding: 10px 20px;
  box-shadow: none;
  background-color: transparent;
  justify-self: end;
  color: #777676;
  align-content: center;
  border-radius: 20px;
  margin-left: 2%;
  margin-right: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
}

.BtnOption:hover {
  transform: scale(1.05);
  color: #262626;
  box-shadow: 2px 2px 10px 0px #00000040;
}

.BtnOption:active {
  transform: scale(0.95);
}

.search-bar {
  width: 90%;
  height: 34px;
  align-items: center;
}

.filterSearch {
  height: 34px;
  width: 100%;
}

.creative-bar {
  width: 100%;
  z-index: 2;
}

.details-modal {
  width: 100%;
  height: 100%;
}

.icon-search {
  position: absolute;
  align-items: center;
  margin-left: 0.5%;
}

.justifySelfStart {
  justify-self: flex-start;
}

.textNewCard {
  font-family: Roboto;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.25px;
}

.buttonMainSales {
  background-color: transparent;
  border-radius: 20px;
  width: 100px;
  height: 34px;
  display: flex;
  margin-left: 0%;
  margin-right: 0%;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
}

.buttonMainSales:hover {
  background-color: #ceced0;
}

.textAlignCenter {
  text-align: center !important;
  align-content: center;
}

.containerSection {
  height: 100%;
  margin-top: 2%;
}

.salesChart {
  width: 100%;
}

.salesContainer {
  align-content: center;
  align-items: center;
  padding-right: 20%;
  padding-left: 20%;
}

.saleDetails {
  justify-items: center;
}

.saleDetailCard {
  width: 200px;
  height: 100px;
  box-shadow: 2px 2px 10px 0px #85858540;
  border-radius: 10px;
  align-content: center;
  padding-left: 25px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.saleDetailCard:hover {
  transform: scale(1.05);
  box-shadow: 4px 4px 20px 0px #85858580;
}

.titleCard {
  font-size: 0.8rem;
  font-weight: 500;
  color: #8e8e8e;
}

.valueCard {
  font-size: 1.5rem;
  font-weight: 500;
}

.detailsChart {
  padding: 2vh 3vh 2vh 5vh;
}

.optionsChart {
  padding-left: 50%;
}

.chartButtonSelected {
  width: auto;
  height: auto;
  padding: 5px 10px;
  color: #3281ff;
  justify-self: end;
  align-content: center;
  border-radius: 10px;
  box-shadow: 2px 2px 10px 0px #00000040;
  margin-left: 2%;
  margin-right: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.chartButtonSelected:hover {
  transform: scale(1.05);
  box-shadow: 4px 4px 15px 0px #00000070;
}

.chartButtonSelected:active {
  transform: scale(0.95);
}

.chartButton {
  width: auto;
  height: auto;
  padding: 10px 20px;
  box-shadow: none;
  background-color: transparent;
  justify-self: end;
  color: #777676;
  align-content: center;
  border-radius: 10px;
  margin-left: 2%;
  margin-right: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
}

.chartButton:hover {
  transform: scale(1.05);
  color: #262626;
  box-shadow: 2px 2px 10px 0px #00000040;
}

.chartButton:active {
  transform: scale(0.95);
}

.titleSales {
  font-size: 1rem;
  font-weight: 500;
  color: #8e8e8e;
}

.subtitleSales {
  font-size: 1rem;
  font-weight: 500;
  color: #8e8e8e;
}

.subtitleSpanSales {
  font-size: 1rem;
  font-weight: 500;
  color: #65c565;
}

.subtitleSpanSales.negative {
  color: #ff4d4d;
}

.totalValueSales {
  font-size: 1.5rem;
  font-weight: 500;
}

.alignCenter {
  align-items: center;
  align-content: center;
}

.negativeMargin6 {
  margin-top: -6%;
}

.tab-content {
  padding: 0rem !important;
  padding-top: 1% !important;
}

.b-tabs .tab-content {
  background-color: green !important;
  position: relative;
  overflow: visible;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}

ul {
  z-index: 10 !important;
}

input {
  padding-left: 28px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: padding-left 0.2s ease;
}

.tabs.is-toggle li.is-active a {
  background-color: transparent;
  border-color: #262626;
  color: black;
  z-index: 10;
}

body {
  font: size 0.8em !important;
}

input:focus {
  padding-left: 25px;
}

.container-details {
  width: 100%;
  height: 70vh;
}

.label-container {
  padding: 5px;
}

.label-container-total {
  padding: 5px;
  border-top: solid #303034;
}

.detail-label {
  display: block;
  color: #303034;
  font-size: 1rem;
}

.detail-text {
  display: block;
  font-weight: 500;
  line-height: 1.5;
  color: #303034;
  font-size: 1rem;
}

.label-container-items {
  display: flex;
  align-items: center;
  /* Alinea los elementos verticalmente al centro */
}

.detail-label-items,
.detail-text-items {
  margin-right: 10px;
  /* Espacio entre los labels, si es necesario */
}
</style>
