<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <b-modal
    :active="show"
    trap-focus
    has-modal-card
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-modal
    class="custom-modal"
    @update:active="$emit('update:show', $event)"
    @close="$emit('cancel')"
  >
    <div class="modal-card custom-modal-card">
      <div class="modal-content rounded-lg bg-white p-4 w-full">
        <div class="modal-header">
          <div class="grid grid-cols-2 grid-rows-3 gap-4">
            <div class="col-span-2 flex justify-center">
              {{ $t("sales_website.orders") }}
            </div>
            <div class="col-span-2 row-start-2 flex justify-center">
              {{ userData.name }} | {{ userData.email }}
            </div>
            <div class="row-start-3 flex justify-end">
              <div class="grid search-bar">
                <input
                  v-model="filter"
                  :placeholder="$t('sales_website.search')"
                  icon-name="find"
                  class="filterSearch"
                />
                <b-icon class="icon-search" name="search" />
              </div>
            </div>
            <div class="row-start-3 flex justify-start">
              <DatePickerComponent @dateChange="handleDateChange" />
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="modal-table">
            <BTable
              :data="customerOrdersData"
              :columns="ordersColumns"
              :current-page="currentPage"
              :on-page-change="onPageChange"
              :tooltipText="$t('sales_website.tooltip_text')"
              :noDataMessage="$t('sales_website.table_without_data')"
              :onRowClick="getOrderDetails"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="text-white bg-gray-800 hover:bg-gray-700 hover:text-white font-bold px-4 py-2 rounded shadow-md"
            @click="$emit('accept')"
          >
            {{ $t("modal_order_details.accept_text") }}
          </button>
        </div>
      </div>
    </div>
    <OrderDetailsModal
      :show="isModalVisible"
      :title="$t('modal_order_details.title')"
      cancel-button-disabled
      :accept-text="$t('modal_order_details.accept_text')"
      accept-text-style-type="dark"
      :center-buttons="true"
      :order="order"
      @cancel="closeModal"
      @accept="closeModal"
    />
  </b-modal>
</template>

<script>
//#region GENERALS
import * as Sentry from "@sentry/browser";
import "vue2-datepicker/index.css";
import { debounce } from "lodash-es";
//#endregion

//#region UTILS
import { dateFormart, orderFormat } from "@/utils/helpers";
//#endregion

//#region SERVICES
import apiOrders from "@/services/orders";
//#endregion

//#region COMPONENTS
import DatePickerComponent from "@/views/Sales/datePicker.vue";
import BTable from "@/components/BTable.vue";
import BIcon from "@/components/BIcon";
import OrderDetailsModal from "@/components/OrderDetailsModal";
//#endregion

export default {
  name: "CustomerOrdersModal",
  components: {
    DatePickerComponent,
    BTable,
    BIcon,
    OrderDetailsModal
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    userData: {
      type: Object,
      required: true
    },
    websiteId: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isConfirmationModalVisible: false,
      filter: "",
      dateStart: "",
      dateEnd: "",
      debouncedSearch: null,
      ordersColumns: [
        {
          label: this.$t("table_orders_headers.number"),
          field: "orderId",
          formatter: row => "#" + row
        },
        {
          label: this.$t("table_orders_headers.date"),
          field: "createdAt",
          formatter: dateFormart
        },
        {
          label: this.$t("table_orders_headers.items"),
          field: "items",
          formatter: row => {
            const { orderItems } = orderFormat(row);
            return orderItems;
          }
        },
        {
          label: this.$t("table_orders_headers.quantity"),
          field: "items",
          formatter: row => {
            const { quantity } = orderFormat(row);
            return quantity;
          }
        },
        {
          label: this.$t("table_orders_headers.payment"),
          field: "paymentType",
          formatter: row => this.$t("payment_types." + row)
        },
        {
          label: this.$t("table_orders_headers.delivery"),
          field: "deliveryType",
          formatter: row => this.$t("delivery_types." + row),
          icon: "truck-fast"
        },
        {
          label: this.$t("table_orders_headers.total"),
          field: "total",
          formatter: row => "$" + row
        }
      ],
      customerOrdersData: {},
      currentPage: 1,
      isModalVisible: false,
      order: {}
    };
  },
  computed: {},
  watch: {
    filter() {
      this.debouncedSearch();
    },
    dateStart() {
      this.getOrders();
    },
    dateEnd() {
      this.getOrders();
    },
    userData() {
      this.getOrders();
    },
    order() {
      this.openModal();
    }
  },
  created() {
    this.debouncedSearch = debounce(this.getOrders, 500);
  },
  methods: {
    async handleDateChange({ dateStart, dateEnd }) {
      this.dateStart = dateStart;
      this.dateEnd = dateEnd;
    },
    async getOrders() {
      try {
        if (this.userData) {
          const res = await apiOrders.getOrders(this.websiteId, {
            filter: this.filter,
            dateStart: this.dateStart,
            dateEnd: this.dateEnd,
            page: this.currentPage,
            userId: this.userData._id
          });
          this.customerOrdersData = res.data || [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$buefy.notification.open({
            message: this.$t("api_response.code_401"),
            type: "is-warning"
          });
          this.returnMainPage();
        }
      }
    },
    async getOrderDetails(id) {
      try {
        this.order = {};
        const res = await apiOrders.getOrderDetails(id._id);
        this.order = res.data.data || {};
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$buefy.notification.open({
            message: this.$t("api_response.code_401"),
            type: "is-warning"
          });
          this.returnMainPage();
        }
      }
    },
    onPageChange(page) {
      this.currentPage = page;
      this.getOrders();
    },
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    async returnMainPage() {
      try {
        this.$router.push({
          name: "dashboard"
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-modal {
  width: 100%;
}
.modal-content {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: white;
}

.modal-header {
  margin-bottom: 10px;
  padding: 10px;
}

.modal-body {
  margin-bottom: 10px;
}

.modal-footer {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  margin-bottom: 2px;
  justify-content: center;
}

.search-bar {
  width: 100%;
  height: 34px;
  align-items: center;
}

input {
  padding-left: 28px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.filterSearch {
  height: 34px;
  width: 100%;
}

.icon-search {
  position: absolute;
  align-items: center;
  margin-left: 8px;
}
</style>
