import axios from "axios";

const endpoint = "/api/users";

export default {
  /**
   * Sends a GET request to the `/api/users` endpoint with the specified query parameters.
   * @param query - An object that contains filters and search criteria for the request.
   * @returns The result of the axios GET request.
   */
  getCustomers(websiteId, query) {
    return axios.get(`${endpoint}/websiteId/${websiteId}`, {
      params: query
    });
  }
};
